<template>
    <div>
        <v-card-text class="pb-0 pl-7 pr-7 pt-3" v-if="!smsSended || !smsVerified">
            <v-row>
                <v-col class="pb-0" style="color:#999999; font: normal normal 600 16px/37px Apple SD Gothic Neo;">아이디</v-col>
            </v-row>
            <v-row>
                <v-col class="pt-0 pb-5">
                    <v-text-field outlined hide-details dense placeholder="아이디를 입력해 주세요" :error="formStatus['user_id'].err" :ref="'user_id'" v-model="formData.user_id" @keyup="checkChanged('user_id')"
                                  style="height:40px;">
                        <template v-slot:append v-if="formStatus['user_id'].err_msg">
                            <div style="color:#FF4D4D; font-size:13px; padding-top:5px;">
                                {{ formStatus['user_id'].err_msg }}
                            </div>
                        </template>
                    </v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col class="pt-0 pb-0" style="color:#999999; font: normal normal 600 16px/37px Apple SD Gothic Neo;">휴대폰 번호</v-col>
            </v-row>
            <v-row>
                <v-col class="pt-0 pb-5">
                    <v-text-field outlined hide-details dense placeholder="휴대폰 번호를 입력해 주세요" :error="formStatus['user_mdn'].err" :ref="'user_mdn'" v-model="formData.user_mdn"
                                  @keyup="checkChanged('user_mdn')" style="height:40px;">
                        <template v-slot:append v-if="formStatus['user_mdn'].err_msg || formStatus['user_mdn'].info_msg">
                            <div style="color:#FF4D4D; font-size:13px; padding-top:5px;" v-if="formStatus['user_mdn'].err_msg">
                                {{ formStatus['user_mdn'].err_msg }}
                            </div>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="3" class="pt-0 pl-0">
                    <v-btn color="#CCCCCC" width="94px" dark elevation="0" style="font-size:16px;" v-if="smsVerified">인증완료</v-btn>
                    <v-btn color="#CCCCCC" width="94px" dark elevation="0" style="font-size:16px;" v-else-if="smsSended && !smsVerified">발송완료</v-btn>
                    <v-btn color="#4E87DD" width="94px" dark elevation="0" style="font-size:16px;" v-else-if="formData.user_mdn && !smsVerified" @click="sendSms">인증받기</v-btn>
                    <v-btn color="#CCCCCC" width="94px" dark elevation="0" style="font-size:16px;" v-else>인증받기</v-btn>
                </v-col>
            </v-row>
            <v-row v-if="smsSended && !smsVerified">
                <v-col class="pt-0 pb-5">
                    <v-text-field outlined hide-details dense placeholder="인증번호를 입력해 주세요" :error="formStatus['sms_verify_value'].err" :ref="'sms_verify_value'" v-model="formData.sms_verify_value"
                                  @keyup="checkChanged('sms_verify_value')" style="height:40px;">
                        <template v-slot:append v-if="formStatus['sms_verify_value'].err_msg || formStatus['sms_verify_value'].info_msg">
                            <div style="color:#FF4D4D; font-size:13px; padding-top:5px;" v-if="formStatus['sms_verify_value'].err_msg">
                                {{ formStatus['sms_verify_value'].err_msg }}
                            </div>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="3" class="pt-0 pl-0">
                    <v-btn color="#4E87DD" width="94px" dark elevation="0" style="font-size:16px;" v-if="formData.sms_verify_value && smsSended && !smsVerified" @click="verifySms">확인</v-btn>
                    <v-btn color="#CCCCCC" width="94px" dark elevation="0" style="font-size:16px;" v-else>확인</v-btn>
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-text class="pb-0 pl-7 pr-7 pt-3" v-if="smsSended && smsVerified">
            <v-row>
                <v-col class="pb-0" style="color:#999999; font: normal normal 600 16px/37px Apple SD Gothic Neo;">새 비밀번호</v-col>
            </v-row>
            <v-row>
                <v-col class="pt-0 pb-5">
                    <v-text-field type="password" outlined hide-details dense placeholder="새 비밀번호를 입력해 주세요" :error="formStatus['new_password'].err" :ref="'new_password'"
                                  v-model="formData.new_password" @keyup="checkChanged('new_password')" style="height:40px;">
                        <template v-slot:append v-if="formStatus['new_password'].err_msg">
                            <div style="color:#FF4D4D; font-size:13px; padding-top:5px;">
                                {{ formStatus['new_password'].err_msg }}
                            </div>
                        </template>
                    </v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col class="pb-0" style="color:#999999; font: normal normal 600 16px/37px Apple SD Gothic Neo;">새 비밀번호 확인</v-col>
            </v-row>
            <v-row>
                <v-col class="pt-0 pb-5">
                    <v-text-field type="password" outlined hide-details dense placeholder="새 비밀번호 확인을 입력해 주세요" :error="formStatus['new_password_confirm'].err" :ref="'user_id'"
                                  v-model="formData.new_password_confirm" @keyup="checkChanged('new_password_confirm')" style="height:40px;">
                        <template v-slot:append v-if="formStatus['new_password_confirm'].err_msg">
                            <div style="color:#FF4D4D; font-size:13px; padding-top:5px;">
                                {{ formStatus['new_password_confirm'].err_msg }}
                            </div>
                        </template>
                    </v-text-field>
                </v-col>
            </v-row>
        </v-card-text>

    </div>

</template>

<script>
import authApi from '@/api/auth'

export default {
    data: () => ({
        formData: {
            user_id: '',
            user_mdn: '',
            sms_verify_value: '',
            tran_token: '',
            new_password: '',
            new_password_confirm: '',
        },
        formStatus: {
            user_id: {
                err: false,
                err_msg: '',
            },
            user_mdn: {
                err: false,
                err_msg: '',
                info_msg: '',
            },
            sms_verify_value: {
                err: false,
                err_msg: '',
            },
            new_password: {
                err: false,
                err_msg: '',
            },
            new_password_confirm: {
                err: false,
                err_msg: '',
            }
        },
        smsSended: false,
        smsVerified: false,
        findDone: false,
        userID: '',
    }),
    computed: {},
    mounted() {
        this.$nextTick(() => {
            this.$refs.user_id.focus()
        })
    },
    methods: {
        doSubmit() {
            if (this.findDone) {
                this.$emit('cancel')
            } else if (this.smsSended && this.smsVerified) {
                let newPw = this.formData.new_password
                let newPwCfm = this.formData.new_password_confirm
                let succ = true
                if (!newPw) {
                    this.updateErrStatus('new_password', true, '새로운 패스워드를 입력해 주세요')
                    succ = false
                } else {
                    this.updateErrStatus('new_password', false, '')
                }
                if (!newPwCfm) {
                    this.updateErrStatus('new_password', true, '새로운 패스워드 확인을 입력해 주세요')
                    succ = false
                } else if (newPw != newPwCfm) {
                    this.updateErrStatus('new_password', true, '새로운 패스워드와 확인이 일치하지 않습니다')
                    succ = false
                } else {
                    this.updateErrStatus('new_password', false, '')
                }
                if (!succ) {
                    return
                }

                authApi.resetPw({
                        tran_token: this.formData.tran_token,
                        new_password: newPw,
                        new_password_confirm: newPwCfm,
                    },
                    (va) => {
                        va.$store.dispatch('openAlert', {
                            message: '비밀번호가 변경되었습니다. 다시 로그인 해주세요',
                            closeCb: (va) => {
                                va.$emit('cancel')
                            },
                            va: va,
                        })

                    }, (err, va) => {
                        va.$store.dispatch('openAlert', {
                            message: '패스워드 변경을 실패 했습니다',
                            sub_message: err
                        })
                    }, this)
            } else if (!this.smsSended) {
                this.sendSms()
            } else if (!this.smsVerified) {
                this.verifySms()
            }
        },
        closePopup() {
            this.$emit('cancel')
        },
        checkChanged(t) {
            let val = this.formData[t]
            let s = this.formStatus[t]
            if (!s) {
                return
            }

            if (t == 'user_id') {
                if (!val) {
                    this.updateErrStatus('user_id', true, '아이디를 입력해 주세요')
                } else {
                    this.updateErrStatus('user_id', false, '')
                }
            } else if (t == 'user_mdn') {
                let mdnType = /^010[0-9+]{7,8}$/;
                if (!val) {
                    this.updateErrStatus('user_mdn', true, '휴대폰 번호를 입력해 주세요')
                } else if (!mdnType.test(val)) {
                    this.updateErrStatus('user_mdn', true, '잘못된 휴대폰 번호 형식 입니다')
                    
                } else {
                    this.updateErrStatus('user_mdn', false, '')
                }

            }

        },
        checkFormErr() {
            for (let k in this.formStatus) {
                if (this.formStatus[k].err) {
                    return true
                }
            }

            return false
        },
        updateErrStatus(target, err, errMsg) {
            let status = this.formStatus[target]
            let ref = null

            if (!errMsg) {
                errMsg = ''
            }

            if (status) {
                status.err = err
                status.err_msg = errMsg
                if (err && ref) {
                    ref.focus()
                }
            }
        },
        sendSms() {
            let succ = true
            let userId = this.formData.user_id
            if (!userId) {
                this.updateErrStatus('user_id', true, '아이디를 입력해 주세요;')
                succ = false
            }
            let userMdn = this.formData.user_mdn
            if (userMdn == '') {
                this.updateErrStatus('user_mdn', true, '휴대폰 번호를 입력해 주세요')
                succ = false
            }
            let mdnType = /^010[0-9+]{7,8}$/;
            if (!mdnType.test(userMdn)) {
                this.updateErrStatus('user_mdn', true, '잘못된 휴대폰 번호 형식 입니다')
                succ = false
            }

            if (!succ) {
                return
            }

            authApi.sendSms({
                auth_type: 'FIND_ADMIN_PW',
                user_id: userId,
                user_mdn: userMdn
            }, (tranToken, va) => {
                va.smsSended = true
                va.smsVerified = false
                va.formData.sms_verify_value = ''
                va.formData.tran_token = tranToken

                va.$nextTick(() => {
                    va.$refs.sms_verify_value.focus()
                })
            }, (err, va) => {
                let msg = '인증 문자 발송을 실패 했습니다'
                let subMsg = err

                va.$store.dispatch('openAlert', {
                    message: msg,
                    sub_message: subMsg,
                })
            }, this)
        },
        verifySms() {
            if (this.formData.sms_verify_value == '') {
                this.updateErrStatus('sms_verify_value', true, '인증번호를 입력해 주세요')
                return
            }

            authApi.verifySms({
                auth_type: 'FIND_ADMIN_PW',
                tran_token: this.formData.tran_token,
                sms_verify_value: this.formData.sms_verify_value,
            }, (verifyResult, va) => {
                if (verifyResult == 'SUCCESS' || verifyResult == 'ALREADY_VERIFIED') {
                    va.$store.dispatch('openAlert', {
                        message: '인증 되었습니다',
                        va: va,
                        closeCb: function (va) {
                            va.smsVerified = true
                            va.updateErrStatus('user_mdn', false, '')

                            va.doSubmit()
                        }
                    })
                } else {
                    let msg = '인증을 실패 했습니다'
                    let subMsg = ''
                    // NO_TRAN, ALREADY_VERIFIED, INCORRECT
                    if (verifyResult == 'NO_TRAN') {
                        subMsg = '인증 정보가 만료되었습니다'
                    } else if (verifyResult == 'INCORRECT') {
                        subMsg = '인증번호가 일치하지 않습니다'
                    }

                    va.$store.dispatch('openAlert', {
                        message: msg,
                        sub_message: subMsg,
                        va: va,
                        closeCb: function (va) {
                            va.formStatus.user_mdn.verified = false
                            va.formStatus.user_mdn.verified_val = ''
                        }
                    })
                }
            }, (err, va) => {
                va.$store.dispatch('openAlert', {
                    message: '인증을 실패 했습니다.',
                    va: va,
                    closeCb: function (va) {
                        va.formStatus.user_mdn.verified = false
                        va.formStatus.user_mdn.verified_val = ''
                    }
                })
            }, this)
        },
    }
}
</script>
